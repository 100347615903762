<template>
<div class="container-fluid">
  <base-dialog :show="!!error" title="An Error" @close="handleError"
    ><p>{{ error }}</p></base-dialog
  >
  <base-dialog fixed :show="isLoading" title="Processing...">
    <base-spinner></base-spinner>
  </base-dialog>
  <form class="p-5 border">
    <fieldset>
      <legend v-if="!update">Add Client</legend>
      <legend v-else>Update Client</legend>
      <div class="row">
        <input type="hidden" name="id" id="id" v-model.trim="id">
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="party_name"> Name <span>*</span> </label>
          <input
            type="text"
            id="party_name"
            ref="party_name"
            name="party_name"
            class="form-control form-control-sm"
            :class="{ invalid: invalidName }"
            v-model.trim="party_name"
            @blur="validInput"
          />
          <span v-if="invalidName" :class="{ invalid: invalidName }"
            >Invalid Name</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="party_contact"> Contact Person <span>*</span></label>
          <input
            type="text"
            id="party_contact"
            ref="party_contact"
            name="party_contact"
            class="form-control form-control-sm"
            :class="{ invalid: invalidContactdName }"
            v-model.trim="party_contact"
            @blur="validInput"
          />
          <span
            v-if="invalidContactdName"
            :class="{ invalid: invalidContactdName }"
            >Invalid Contact Person</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="party_phone_number"> Contact Number</label>
          <input
            type="number"
            id="party_phone_number"
            name="party_phone_number"
            class="form-control form-control-sm"
            v-model.number="party_phone_number"
          />
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="party_whatsapp_number"
            >Whatsapp Number<span>*</span></label
          >
          <input
            type="number"
            id="party_whatsapp_number"
            ref="party_whatsapp_number"
            name="party_whatsapp_number"
            class="form-control form-control-sm"
            :class="{ invalid: invalidWPNumber }"
            v-model.number="party_whatsapp_number"
            @blur="validNumber"
            @keyup="validNumber"
          />
          <span v-if="invalidWPNumber" :class="{ invalid: invalidWPNumber }"
            >Invalid Whatsapp Number</span
          >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 p-2">
          <label for="party_email">Email<span>*</span></label>
          <input
            type="email"
            id="party_email"
            ref="party_email"
            name="party_email"
            class="form-control form-control-sm"
            :class="{ invalid: invalidemail }"
            v-model.number="party_email"
            @blur="validInput"
          />
          <span v-if="invalidemail" :class="{ invalid: invalidemail }"
            >Invalid Email</span
          >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 p-2">
          <label for="party_adress">Address<span>*</span></label>
          <textarea
            id="party_adress"
            ref="party_adress"
            name="party_adress"
            class="form-control form-control-sm"
            :class="{ invalid: invalidaddress }"
            v-model.number="party_adress"
            @blur="validInput"
          ></textarea>
          <span v-if="invalidaddress" :class="{ invalid: invalidaddress }"
            >Address Should Not be Blank</span
          >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-if="update">
          <!--<button class="btn btn-sm" @click="close">Close</button>
          <button class="btn btn-sm" @click.prevent="updateData">Save</button>-->
             
            <base-button  @click.prevent="updateData" mode="btn"> Update </base-button >
            <base-button @click="close" mode="btn"> Close</base-button >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-else>
          
          <base-button @click="close" mode="btn"> Close</base-button >
          <base-button  @click.prevent="saveData(true)" mode="btn"> Save & Close </base-button >
          <base-button  @click.prevent="saveData" mode="btn"> Save & New </base-button >
          <!--<button class="btn btn-sm" @click="close">Close</button>
          <button class="btn btn-sm" @click.prevent="saveData(true)">
            Save & Close
          </button>
          <button class="btn btn-sm" @click.prevent="saveData">
            Save & New
          </button>-->
        </div>
      </div>
    </fieldset>
  </form>
</div>
</template>
<script>
export default {
  emits: ["close", "load","msg"],
  props: ["update"],
  data() {
    return {
      id:null,
      party_name: null,
      party_contact: null,
      party_phone_number: null,
      party_whatsapp_number: null,
      party_email: null,
      party_adress: null,
      isLoading: false,
      error: null,
      invalidContactdName: false,
      invalidName: false,
      invalidWPNumber: false,
      invalidemail: false,
      invalidaddress: false,
      valid: null,
    };
  },
  methods: {
    async saveData(selfclose = false) {
      let valid = null;
      this.validInput();
      if (
        this.invalidName ||
        this.invalidContactdName ||
        this.invalidemail ||
        this.invalidWPNumber ||
        this.invalidaddress
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("admin/addclient", {
            name: this.party_name,
            contact: this.party_contact,
            phone: this.party_phone_number,
            whatsapp: this.party_whatsapp_number,
            email: this.party_email,
            address: this.party_adress,
          });
        } catch (error) {
          alert('client error alert')
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          if (selfclose == true) {
            this.$emit("close");
          }
          this.$emit("msg","Client Added Successfully")
          this.$emit("load");
          this.party_name = null;
          this.party_contact = null;
          this.party_phone_number = null;
          this.party_whatsapp_number = null;
          this.party_email = null;
          this.party_adress = null;
        }, 2000);
      }
    },
    getdata() {
      if (this.update) {
        this.$store.dispatch("admin/singleGetClient", { id: this.update });
        this.isLoading = true;
        setTimeout(() => {
          this.setData();
          this.isLoading=false;
        }, 2000);
      }
    },
    handleError() {
      this.error = null;
    },
    close() {
      this.$emit("close");
    },
    validInput() {
      if (!this.party_name) {
        this.invalidName = true;
        this.$refs.party_name.focus();
      } else if (!this.party_contact) {
        this.invalidContactdName = true;
        this.$refs.party_contact.focus();
      } else if (this.$refs.party_whatsapp_number.value.length < 10) {
        this.validNumber();
      } else if (!this.$refs.party_email.value.includes("@")) {
        this.invalidemail = true;
        this.$refs.party_email.focus();
      } else if (!this.party_adress) {
        this.invalidaddress = true;
        this.$refs.party_adress.focus();
      }
      if (this.party_name) {
        this.invalidName = false;
      }
      if (this.party_contact) {
        this.invalidContactdName = false;
      }
      if (this.$refs.party_email.value.includes("@")) {
        this.invalidemail = false;
      }
      if (
        (this.$refs.party_whatsapp_number.value.length == 10 &&
          this.$refs.party_whatsapp_number.value.startsWith("9")) ||
        this.$refs.party_whatsapp_number.value.startsWith("7") ||
        this.$refs.party_whatsapp_number.value.startsWith("6")
      ) {
        this.invalidWPNumber = false;
      }
      if (this.party_adress) {
        this.invalidaddress = false;
      }
    },
    validNumber() {
      if (
        this.$refs.party_whatsapp_number.value.length > 10 ||
        this.$refs.party_whatsapp_number.value.length < 10 ||
        this.$refs.party_whatsapp_number.value.length == 10
      ) {
        if (this.$refs.party_whatsapp_number.value.length == 10) {
          if (
            this.$refs.party_whatsapp_number.value.startsWith("8") ||
            this.$refs.party_whatsapp_number.value.startsWith("9") ||
            this.$refs.party_whatsapp_number.value.startsWith("7") ||
            this.$refs.party_whatsapp_number.value.startsWith("6")
          ) {
            this.invalidWPNumber = false;
          } else {
            this.invalidWPNumber = true;
            this.$refs.party_whatsapp_number.focus();
          }
        } else {
          this.invalidWPNumber = true;
          this.$refs.party_whatsapp_number.focus();
        }
      } else {
        this.invalidWPNumber = true;
        this.$refs.party_whatsapp_number.focus();
      }
    },
    setData() {
      const Data = this.$store.getters["admin/getSingleClient"];
      this.id=Data[0].int_party_id;
      this.party_name = Data[0].txt_name;
      this.party_contact = Data[0].txt_contact_person;
      this.party_phone_number = Data[0].txt_mobile;
      this.party_whatsapp_number = Data[0].txt_mobile_whatsapp;
      this.party_email = Data[0].txt_email;
      this.party_adress = Data[0].txt_address;
    },
    async updateData() {
      let valid = null;
      this.validInput();
      if (
        this.invalidName ||
        this.invalidContactdName ||
        this.invalidemail ||
        this.invalidWPNumber ||
        this.invalidaddress
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("admin/updateClient", {
            id:this.id,
            name: this.party_name,
            contact: this.party_contact,
            phone: this.party_phone_number,
            whatsapp: this.party_whatsapp_number,
            email: this.party_email,
            address: this.party_adress,
          });
        } catch (error) {
          alert('client error alert')
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          this.$emit("close");
          this.$emit("load");
          this.$emit("msg","Client Updated Successfully")
          this.party_name = null;
          this.party_contact = null;
          this.party_phone_number = null;
          this.party_whatsapp_number = null;
          this.party_email = null;
          this.party_adress = null;
        }, 2000);
      }
    },

  },
  created() {
    this.getdata();
  },
};
</script>
<style scoped>

</style>
